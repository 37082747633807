<script>
import CultureChart from "@/components/CultureChart.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
	components: { CultureChart },
	data() {
		return {
			filter: {
				user_id: {
					selected: null,
				},
				period_id: {
					selected: null,
				},
			},
			user: {},
			votes: {},
			periods: {},
		};
	},
	created() {
		const id = this.$route.params.id;
		this.filter.user_id.selected = id ? id : null;
		this.loadUser();
		this.loadVotes();
		this.loadFilterOptions();
	},
	computed: {
		...mapState("votesStore", ["votesCount"]),
		...mapGetters({
			readUser: "getUsers/readUsers",
			readPeriods: "getPeriods/readPeriods",
		}),
	},
	methods: {
		...mapMutations("votesStore", [
			"SET_VOTES_COUNT_ID",
			"SET_VOTES_COUNT_PERIOD",
		]),
		...mapActions("votesStore", ["votesActions"]),
		...mapMutations({
			setUserId: "getUsers/SET_ID",
		}),
		...mapActions({
			getUser: "getUsers/getUsers",
			getPeriods: "getPeriods/getPeriods",
		}),
		loadUser() {
			const id = this.filter.user_id.selected;
			this.setUserId(id);
			this.getUser().then((response) => {
				if (response) {
					this.user = Object.assign({}, this.user, this.readUser[0]);
				}
			});
			this.getUser().then((response) => {
				if (response) {
					for (const property in this.user) {
						this.$set(
							this.user,
							property,
							this.readUser[0][property]
						);
					}
				}
			});
		},
		loadVotes() {
			const { user_id, period_id } = this.filter;
			this.SET_VOTES_COUNT_ID(user_id.selected);
			this.SET_VOTES_COUNT_PERIOD(period_id.selected);
			this.votesActions("getVotesCount");
		},
		loadFilterOptions() {
			this.getPeriods().then((response) => {
				if (response) {
					if (this.readPeriods.length > 0) {
						for (let p in this.readPeriods) {
							this.periods[this.readPeriods[p].id] =
								this.readPeriods[p].name;
						}
					}
				}
			});
		},
	},
};
</script>
<template>
	<div class="main">
		<div
			class="flex flex-col items-center max-w-4xl content-wrap bg-white md:border-2 border-gray p-6 md:shadow-md mx-auto"
		>
			<div class="w-full md:flex">
				<div
					class="md:inline-flex mx-auto mb-3 md:mb-2 md:mr-2 bg-gray-light flex items-center justify-center flex-none overflow-hidden"
					:class="user.photo ? 'w-48 h-48' : 'p-10'"
				>
					<font-awesome-icon
						v-if="!user.photo"
						class="w-20 h-20 block green"
						icon="fa-solid fa-user"
					/>
					<img
						v-else
						class="block w-full h-full object-cover transform rotate-90"
						:src="user.photo"
						:alt="
							!user.name || user.basic_info
								? `${user.first_name} ${user.last_name}`
								: user.name
						"
					/>
				</div>
				<div
					class="info md:flex w-full items-center justify-center lg:justify-start flex-grow md:mb-2 gradient-green text-white"
				>
					<div class="w-full p-3">
						<p class="">
							<span
								class="b text-2xl"
								v-capitalizeWords="
									!user.name || user.basic_info
										? `${user.first_name} ${user.last_name}`
										: user.name
								"
							></span
							><span
								class="t block"
								v-capitalizeWords="user.work_position"
							></span>
						</p>
						<hr class="bg-yellow my-2 h-0.5 w-full" />
						<p
							v-capitalizeWords="user.territory"
							class="text-lg"
						></p>
						<p v-capitalizeWords="user.region" class="text-lg"></p>
						<p v-capitalizeWords="user.agency" class="text-lg"></p>
					</div>
				</div>
			</div>
			<div class="stat w-full py-3 md:py-0 text-center md:flex">
				<div class="mb-3 md:mb-0 md:w-1/2 md:mr-2 border border-red">
					<p class="stat__count text-3xl py-6 red b">
						{{ votesCount.emitted_votes || 0 }}
					</p>
					<p class="stat__name black text-lg pb-3">
						Reconocimientos
						<span class="block b">enviados</span>
					</p>
				</div>
				<div class="md:w-1/2 border border-red">
					<p class="stat__count text-3xl py-6 red b">
						{{ votesCount.received_votes || 0 }}
					</p>
					<p class="stat__name black text-lg pb-3">
						Reconocimientos
						<span class="block b">recibidos</span>
					</p>
				</div>
			</div>
			<label
				class="block w-full md:mt-2 gradient-green px-3 py-2"
				for="period"
			>
				<span class="text-lg inline-block w-full md:w-1/2 text-white"
					>Filtrar reconocimientos:</span
				>
				<select
					@change="loadVotes"
					class="w-full md:w-1/2 px-4 py-2 text-lg bg-white rounded-md h-12 border border-green"
					name="period"
					v-model="filter.period_id.selected"
				>
					<option :value="null">Seleccionar periodo</option>
					<option :value="null">Global</option>
					<option v-for="(o, i) in periods" :key="i" :value="i">
						{{ o }}
					</option>
				</select>
			</label>
			<CultureChart class="w-full max-w-4xl" :filters="filter" />
		</div>
	</div>
</template>
<style lang="scss" scoped>
.content-wrap {
	@media (max-width: 767px) {
		width: 100%;
	}
}
</style>
